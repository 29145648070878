import { css } from "linaria";
import { styled } from "linaria/react";
import React, { ReactNode } from "react";
import clsx from "classnames";
import { muiTheme, theme } from "../Theme/Theme";
import SvgDotPattern from "../misc/DotPattern/DotPattern";

export interface HeadingProps {
  className?: string;
  heading: ReactNode;
  pattern?: boolean;
  underline?: boolean;
  subheading?: ReactNode;
  underlineAlign?: "left" | "right";
}

const headingStyles = css`
  font-size: 3.6rem;
  color: ${theme.color.primaryDark};
  font-weight: 400;
  display: inline-flex;
  position: relative;
`;

export const underlineStyle = css`
  &::after {
    height: 6px;
    width: 45%;
    content: "";
    background-color: ${theme.color.primary};
    position: absolute;
    bottom: -12px;
  }
`;

const Subheading = styled.p`
  font-size: 1.8rem;
  font-weight: 300;
  color: ${theme.color.text};

  max-width: 500px;
  margin-top: 3.6rem;
`;

const PatternWrapper = styled.div`
  position: absolute;
  right: 0;
  transform: translateX(50%);
  bottom: -2rem;

  ${muiTheme.breakpoints.down('sm')} {
    transform: translateX(20%);
  }
`;

const HeadingInner = (props: HeadingProps) => {
  const {
    heading,
    subheading,
    className = "",
    pattern,
    underline = true,
    underlineAlign = "left"
  } = props;
  return (
    <div
      data-sal-duration="700"
      data-sal="slide-up"
      data-sal-delay={10}
      data-sal-easing="ease"
      className={className}
    >
      <h1
        className={clsx({
          [headingStyles]: true,
          [underlineStyle]: underline,
          heading: true,
        })}
      >
        {pattern && (
          <PatternWrapper>
            <SvgDotPattern />
          </PatternWrapper>
        )}
        {heading}
      </h1>
      {subheading && <Subheading className="subheading">{subheading}</Subheading>}
    </div>
  );
};

export const Heading = styled(HeadingInner)`
  margin-bottom: 9rem;

  ${muiTheme.breakpoints.down('sm')} {
    /* margin-bottom: 4rem; */
  }
`;
